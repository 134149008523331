import Vue from 'vue';
import VueRouter from 'vue-router';

import store from '../store';
import topup from './topup';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/auth/login',
  },
  {
    path: '/cobrand',
    name: 'Login Auth',
    meta: { layout: 'default', auth: false },
    component: () => import('../ui/pages/auth/LoginCobrand.vue'),
  },
  {
    path: '/auth/login',
    name: 'Login',
    meta: { layout: 'default', auth: false },
    component: () => import('../ui/pages/auth/Login.vue'),
  },
  {
    path: '/workspaces',
    name: 'Workspaces',
    meta: { layout: 'topOnly', auth: true },
    component: () => import('../ui/pages/Workspaces.vue'),
  },
  {
    path: '/workspaces-list',
    name: 'Workspaces List',
    meta: { layout: 'topOnly', auth: true },
    component: () => import('../ui/pages/WorkspacesAll.vue'),
  },
  {
    path: '/company',
    name: 'Company',
    meta: { layout: 'topOnly', auth: true },
    component: () => import('../ui/pages/Company.vue'),
  },
  {
    path: '/backoffice-users',
    name: 'User Backoffice',
    meta: { layout: 'topOnly', auth: true },
    component: () => import('../ui/pages/UserBackoffice.vue'),
  },
  {
    path: '/client-documents',
    name: 'Company Documents',
    meta: { layout: 'topOnly', auth: true },
    component: () => import('../ui/pages/CompanyDocuments.vue'),
  },
  {
    path: '/user-activities',
    name: 'User Activities',
    meta: { layout: 'topOnly', auth: true },
    component: () => import('../ui/pages/UserActivities.vue'),
  },
  {
    path: '/sales-monitoring',
    name: 'Sales Monitoring',
    meta: { layout: 'topOnly', auth: true },
    component: () => import('../ui/pages/SalesMonitoring.vue'),
  },
  {
    path: '/migration-spe',
    name: 'Migration SPE',
    meta: { layout: 'topOnly', auth: true },
    component: () => import('../ui/pages/MigrationSPE.vue'),
  },
  {
    path: '/channels',
    name: 'Channel List',
    meta: { layout: 'topOnly', auth: true },
    component: () => import('../ui/pages/Channels.vue'),
  },
  {
    path: '/channel/:channel_id/:workspace_id/profile',
    name: 'Channel Detail',
    meta: { layout: 'topOnly', auth: true },
    component: () => import('../ui/pages/settings/ChannelProfile.vue'),
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    meta: { layout: 'dashboard', auth: true },
    component: () => import('../ui/pages/Dashboard.vue'),
  },
  {
    path: '/broadcast-templates',
    name: 'Broadcast Templates',
    meta: { layout: 'dashboard', auth: true },
    component: () => import('../ui/pages/BroadcastTemplates.vue'),
  },
  {
    path: '/broadcast-templates/create',
    name: 'Create Template',
    meta: { layout: 'dashboard', auth: true },
    component: () => import('../ui/pages/broadcast-templates/CreateTemplate.vue'),
  },
  {
    path: '/broadcast-templates/whatsapp',
    name: 'Broadcast Templates WhatsApp',
    meta: { layout: 'dashboard', auth: true },
    component: () => import('../ui/pages/broadcast-templates/Whatsapp.vue'),
  },
  {
    path: '/chatbot-templates',
    name: 'Chatbot Templates',
    meta: { layout: 'dashboard', auth: true },
    component: () => import('../ui/pages/chatbot-templates/Index.vue'),
  },
  {
    path: '/chatbot-templates/create',
    name: 'Chatbot Templates - Create',
    meta: { layout: 'dashboard', auth: true },
    component: () => import('../ui/pages/chatbot-templates/Create.vue'),
  },
  {
    path: '/chatbot-templates/edit/:id',
    name: 'Chatbot Templates - Edit',
    meta: { layout: 'dashboard', auth: true },
    component: () => import('../ui/pages/chatbot-templates/Edit.vue'),
  },
  {
    path: '/broadcast-templates/whatsapp/:id',
    name: 'Edit Broadcast Templates WhatsApp',
    meta: { layout: 'dashboard', auth: true },
    component: () => import('../ui/components/edit-template/Whatsapp.vue'),
  },
  // {
  //   path: '/broadcast-templates/whatsapp_personal',
  //   name: 'Broadcast Templates WhatsApp Personal',
  //   meta: { layout: 'dashboard', auth: true },
  //   component: () => import('../ui/pages/broadcast-templates/WhatsappPersonal.vue'),
  // },
  {
    path: '/reports/call',
    name: 'Call Reports',
    meta: { layout: 'dashboard', auth: true },
    component: () => import('../ui/pages/reports/ReportCall.vue'),
  },
  {
    path: '/reports/helpdesk',
    name: 'Helpdesk Reports',
    meta: { layout: 'dashboard', auth: true },
    component: () => import('../ui/pages/reports/ReportsHelpdesk.vue'),
  },
  {
    path: '/reports/broadcast',
    name: 'Broadcast Reports',
    meta: { layout: 'dashboard', auth: true },
    component: () => import('@/ui/components/report-broadcast/BatchList.vue'),
  },
  {
    path: '/reports/session',
    name: 'Session Reports',
    meta: { layout: 'dashboard', auth: true },
    component: () => import('../ui/pages/reports/ReportSessionChannel.vue'),
  },
  {
    path: '/reports/session/:channel_id',
    name: 'Session Reports Detail',
    meta: { layout: 'dashboard', auth: true },
    component: () => import('../ui/pages/reports/ReportSessionDetail.vue'),
  },
  {
    path: '/reports/chatlog',
    name: 'Chat Log Reports',
    meta: { layout: 'dashboard', auth: true },
    component: () => import('../ui/pages/reports/ReportChatlog.vue'),
  },
  {
    path: '/reports/download',
    name: 'Download Reports',
    meta: { layout: 'dashboard', auth: true },
    component: () => import('@/ui/pages/reports/ReportsDownload.vue'),
  },
  {
    path: '/reports/broadcast/:id',
    name: 'Broadcast Reports Detail',
    meta: { layout: 'dashboard', auth: true },
    component: () => import('@/ui/components/report-broadcast/BatchDetail.vue'),
  },
  {
    path: '/conversations',
    name: 'Conversations',
    meta: { layout: 'dashboard', auth: true },
    component: () => import('../ui/pages/Conversations.vue'),
  },
  {
    path: '/conversations/labels/:label_id',
    name: 'Conversations with Label',
    meta: { layout: 'dashboard', auth: true },
    component: () => import('../ui/pages/Conversations.vue'),
  },
  {
    path: '/schedule',
    name: 'Notifications Schedule',
    meta: { layout: 'dashboard', auth: true },
    component: () => import('../ui/pages/Schedules.vue'),
  },
  {
    path: '/contacts/list',
    name: 'Contacts',
    meta: { layout: 'dashboard', auth: true },
    component: () => import('../ui/pages/Contacts.vue'),
  },
  {
    path: '/contacts/contact-groups',
    name: 'Group Contacts',
    meta: { layout: 'dashboard', auth: true },
    component: () => import('../ui/pages/ContactGroups.vue'),
  },
  {
    path: '/settings/users',
    name: 'Users',
    meta: { layout: 'dashboard', auth: true },
    component: () => import('../ui/pages/settings/Users.vue'),
  },
  {
    path: '/settings/divisions',
    name: 'Divisions',
    meta: { layout: 'dashboard', auth: true },
    component: () => import('../ui/pages/divisions/Index.vue'),
  },
  {
    path: '/settings/divisions/:id/members',
    name: 'Division Members',
    meta: { layout: 'dashboard', auth: true },
    component: () => import('../ui/pages/divisions/ListMember.vue'),
  },
  {
    path: '/settings/agents',
    name: 'Agents',
    meta: { layout: 'dashboard', auth: true },
    component: () => import('../ui/pages/settings/Agents.vue'),
  },
  {
    path: '/settings/channels',
    name: 'Channels',
    meta: { layout: 'dashboard', auth: true },
    component: () => import('../ui/pages/settings/Channels.vue'),
  },
  {
    path: '/settings/channels/add',
    name: 'Add Channels',
    meta: { layout: 'dashboard', auth: true },
    component: () => import('../ui/pages/settings/ChannelsAdd.vue'),
  },
  {
    path: '/settings/channel/:channel_id/profile',
    name: 'Channel Profile',
    meta: { layout: 'dashboard', auth: true },
    component: () => import('../ui/pages/settings/ChannelProfile.vue'),
  },
  {
    path: '/settings/groups',
    name: 'Groups',
    meta: { layout: 'dashboard', auth: true },
    component: () => import('../ui/pages/settings/Groups.vue'),
  },
  {
    path: '/settings/groups/:id/members',
    name: 'Groups - Members',
    meta: { layout: 'dashboard', auth: true },
    component: () => import('../ui/pages/agent-groups/ListMember.vue'),
  },
  {
    path: '/settings/labels',
    name: 'Labels',
    meta: { layout: 'dashboard', auth: true },
    component: () => import('../ui/pages/settings/Labels.vue'),
  },
  {
    path: '/settings/quick-replies',
    name: 'Quick Replies',
    meta: { layout: 'dashboard', auth: true },
    component: () => import('../ui/pages/settings/QuickReplies.vue'),
  },
  {
    path: '/settings/general',
    name: 'Settings',
    meta: { layout: 'dashboard', auth: true },
    component: () => import('../ui/pages/settings/Settings.vue'),
  },
  {
    path: '/integrations',
    name: 'IntegrationsIndex',
    meta: { layout: 'dashboard', auth: true },
    component: () => import('../ui/pages/integrations/Index.vue'),
  },
  {
    path: '/commerce/product',
    name: 'CommerceProduct',
    meta: { layout: 'dashboard', auth: true },
    component: () => import('../ui/pages/commerce/Products.vue'),
  },
  {
    path: '/document-tracker',
    name: 'Document Tracker',
    meta: { layout: 'dashboard', auth: true },
    component: () => import('../ui/pages/document/CompanyDocuments.vue'),
  },
  {
    path: '/commerce/payments',
    name: 'CommercePayments',
    meta: { layout: 'dashboard', auth: true },
    component: () => import('../ui/pages/commerce/Payments.vue'),
  },
  {
    path: '/deposit/histories',
    name: 'DepositHistories',
    meta: { layout: 'dashboard', auth: true },
    component: () => import('../ui/pages/deposit/Histories.vue'),
  },
  ...topup,
  {
    path: '/coming-soon',
    name: 'Coming Soon',
    meta: { layout: 'dashboard', auth: true },
    component: () => import('../ui/pages/ComingSoon.vue'),
  },
  {
    path: '/404',
    name: 'Page Not Found',
    meta: { layout: 'default', auth: false, type: 'error' },
    component: () => import('../ui/pages/error/404.vue'),
  },
  {
    path: '*',
    redirect: '/404',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const isLogin = store.getters['backOffice/isLogin'];
  const needLogin = to.meta.auth;
  const isErrorPage = to.meta.type && to.meta.type === 'error';

  // console.log({ isLogin, needLogin });

  if (needLogin && !isLogin && !isErrorPage) {
    console.log(isLogin);
    console.log(needLogin);
    console.log(isErrorPage);
    router.push('/auth/login');
  } else if (!needLogin && isLogin && !isErrorPage) {
    router.push('/company');
  } else {
    next();
  }
});

export default router;
